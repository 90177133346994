/* **********************************
 * gnaviPanel
 ********************************** */

(function ($) {
  $.fn.gnavPanel = function (config) {
    var defaults = {
      breakPoint: 767,
      panelCloseSelecter: '#header',
      targetSelecter: '.c-gnaviPanel__item',
      bodyCloseClass: 'is-close-panelGnavSubmenu',
      bodyOpenClass: 'is-open-panelGnavSubmenu',
      blackSheetSelecter: '#navOpenBg-panelGnavSubmenu',
      panelArrowSelecter: '#js-gnaviPanel-active',
      panelCategoryPrefix: '#js-gnaviPanel-',
      panelCtgClassPrefix: 'ctg_',
      categoryHash: {},
      panelArrowOffsetTop: 7,
      menuItemSelector: '.c-listGnav__item',
      menuItemCurrentCls: 'is-current',
    };
    var options = $.extend(defaults, config);
    var breakPoint = options.breakPoint;
    var panelCategoryPrefix = options.panelCategoryPrefix;
    var panelCtgClassPrefix = options.panelCtgClassPrefix;
    var panelCloseSelecter = options.panelCloseSelecter;
    var panelArrowOffsetTop = options.panelArrowOffsetTop;
    var categoryHash = options.categoryHash;
    var targetSelecter = options.targetSelecter;
    var bodyClose = options.bodyCloseClass;
    var bodyOpen = options.bodyOpenClass;
    var blackSheet = options.blackSheetSelecter;
    var menuItemSelector = options.menuItemSelector;
    var menuItemCurrentCls = options.menuItemCurrentCls;
    var $panel = $(options.targetSelecter);
    var $panelArrow = $(options.panelArrowSelecter);
    var $this = $(this);
    var $body = $('html');
    var openSpeed = 30;
    var closeSpeed = 30;

    // 起動時はパネルを隠す
    var status = 'close';
    $panel.hide();

    // 黒透過背景をセットアップ
    if (!$(blackSheet).length) {
      $('body').prepend('<span id="' + blackSheet.replace('#', '') + '" />');
    }
    // パネルアローをセットアップ
    $panelArrow.hide();

    // ホバーイベントをセット
    set_hover();

    function set_hover() {

      // 強制的にパネルを閉じる
      $(panelCloseSelecter).on({
        'mouseenter': function (ev) {
          $(window).trigger('closeGnavSubmenuPanel');
        },
        'mouseleave': function (ev) {
          // do nothing
        }
      });

      // ナビボタン
      $this.on({
        'mouseenter': function (ev) {
          if (status === 'close') {
            $(window).trigger('closeGnavSubmenuPanel');
            open_func(ev);
          }
        },
        'mouseleave': function (ev) {
          $(window).trigger('leaveGnavMenu', [ev]);
        }
      });

      // ナビパネル
      $(targetSelecter).on({
        'mouseenter': function (ev) {
          // do nothing
        },
        'mouseleave': function (ev) {
          $(window).trigger('closeGnavSubmenuPanel');
        }
      });
    }

    function setOpenPanelArrow(ev) {
      var $cMenu = $(ev.target).closest("li");

      // ADD: カレント項目の幅が可変なので幅を取得可能な要素を別途取得する
      var $cMenuLabel = $(ev.target).closest("li").find(".c-listGnav__link__text");

      $panelArrow.show();
      $panelArrow.offset({
        top: $cMenuLabel.offset().top + $cMenuLabel.height() + panelArrowOffsetTop,
        left: $cMenuLabel.offset().left + $cMenuLabel.width() / 2 - $panelArrow.width() / 2
      });

      // 矢印にカテゴリクラスを一旦消してから再度充てる
      var ctgkey = $cMenu.attr('id').replace(panelCategoryPrefix, '');
      $.each(categoryHash, function (val, key) {
        $panelArrow.removeClass(key);
      });
      $panelArrow.addClass(panelCtgClassPrefix + ctgkey);
    }

    function close_func() {
      status = 'close';
      $(menuItemSelector).removeClass(menuItemCurrentCls);
      $body.addClass(bodyClose);
      $body.removeClass(bodyOpen);
      $panel.stop().slideUp(closeSpeed);
      $panelArrow.hide();
    }

    function open_func(ev) {
      status = 'open';
      $(ev.target).closest("li").addClass(menuItemCurrentCls);
      $body.addClass(bodyOpen);
      $body.removeClass(bodyClose);
      $panel.stop().slideDown(openSpeed);
      if ($panel.attr('id') != null) {
        setOpenPanelArrow(ev);
      }
      // パネルオープン後にイベントを送信
      $(window).trigger('openGnavSubmenuPanel', [$panel, $panelArrow]);
    }

    $(window).on('resize', function () {
      if ($panel.is(':visible')) {
        close_func();
        if ($(window).width() >= breakPoint) {
          $('html').removeClass(bodyOpen + ' ' + bodyClose);
          $panel.attr('style', '');
        }
      }
    });

    $(blackSheet).on('click', function (event) {
      event.preventDefault();
      close_func();
    });

    // カスタムイベント: SPメニュー表示時の黒背景制御用
    $(window).on('showBlacksheet', function () {
      $this.off('mouseenter mouseleave');
    });
    $(window).on('hideBlacksheet', function () {
      set_hover();
    });

    // カスタムイベント: closeGnavSubmenuPanelイベントでパネルを閉じる
    $(window).on('closeGnavSubmenuPanel', function () {
      if (status !== 'close') {
        close_func();
      }
    });

    return this;
  };
})(jQuery);

/* **********************************
 * pageSetting
 ********************************** */

(function ($) {
  $.fn.pageSetting = function (config) {

    // args.
    var defaults = {
      key: "fontsize",             // キー名 [data-fontsize]、pagesetting-fontsize
      triggerSelector: ".fontBtn",             // ボタン押下
      valsList: ["small", "medium", "large"],  // 設定値リスト
      dispatchResizeEvent: false,              // 設定後にリサイズイベントを発火する？
      applyBtnSelector: "#js-pagesetting-applyBtn",
      resetBtnSelector: "#js-pagesetting-resetBtn",
      radioNameAttr: "fontsize-change",
    };

    var options = $.extend(defaults, config);
    var $BODY = $(this);

    // field.
    var ATTR_NAME = "data-" + options.key;
    var COOKIE_KEY  = "pagesetting-" + options.key;
    var VALS_LIST = options.valsList;
    var TRIGGER_SELECTOR = options.triggerSelector;
    var APPLY_BTN_SELECTOR = options.applyBtnSelector;
    var RESET_BTN_SELECTOR = options.resetBtnSelector;
    var RADIO_NAME_ATTR = options.radioNameAttr;
    var CURRENT_RADIO_SELECTOR = 'input[name="' + RADIO_NAME_ATTR + '"]';

    // 初期化実行！
    _init();


    // 『標準に戻す』ボタン押下時
    $(RESET_BTN_SELECTOR).on('click', function(e){
      _reset();
      _save( VALS_LIST[0] );
    });

    // 『設定する』ボタン押下時
    $(APPLY_BTN_SELECTOR).on('click', function(e){
      var radioval = $(CURRENT_RADIO_SELECTOR + ':checked').val();
      _save( radioval );
    });

    // // クリックされたボタンの[data-fontsize] を取得
    // $(TRIGGER_SELECTOR).on('click', function(e){
    //   var cVal = $(e.target).closest(TRIGGER_SELECTOR).attr(ATTR_NAME);
    //   _save( cVal );
    // });

    // 初期化
    function _init() {
      var cval = $.cookie( COOKIE_KEY );
      if( VALS_LIST.indexOf( cval ) > -1 ){
        $BODY.attr(ATTR_NAME, cval);
        $(CURRENT_RADIO_SELECTOR).val( [cval] );
        console.log("INIT: " + cval);
      }else{
         // 設定値リストにマッチしない: リセット後にデフォルト値をセット
        _reset();
        _save( VALS_LIST[0] );
      }
    }

    // 設定の反映、保存
    function _save( cval ){
      if( VALS_LIST.indexOf( cval ) > -1 ){
        // 設定する値が設定値リストにマッチ: クッキー保存、bodyの当該属性にセット
        $BODY.attr(ATTR_NAME, cval);
        $.cookie( COOKIE_KEY, cval, { expires: 365, path: '/' });
      }else{
        console.log("ERR: VALS_LIST no match. " + matches);
        _reset(); // 設定値リストにマッチしない: リセット
      }
      // ラジオボタンを設定値に更新
      $(CURRENT_RADIO_SELECTOR).val( [cval] );
    }

    // リセット処理: クッキーを削除、bodyの当該属性を削除
    function _reset() {
      $.cookie(COOKIE_KEY, null, { path: "/" });
      $BODY.removeAttr(ATTR_NAME);
    }

    return this;
  };
})(jQuery);

/*  -----------------------------------------------
    
    _spMenuAccordion.js: SPメニュー内のアコーディオン
      ※スコープを切ってその中で使用すること
    
    -----------------------------------------------*/
var SPMENU_ACCOR_ROOT = '.js-spMenuAccordion';
var SPMENU_ACCOR_BODY = '.js-spMenuAccordion__body';
var SPMENU_ACCOR_HEAD = '.js-spMenuAccordion__head';
var SPMENU_ACCOR_OPEN_CLS = 'is-open';
var SPMENU_ACCOR_SPEED = 200; // 数値を変更しないこと！(mainNaviで参照)

//アコーディオン
function init_spMenuAccordion() {
  // 初期化処理
  $(SPMENU_ACCOR_ROOT).off('click', SPMENU_ACCOR_HEAD);
  $(SPMENU_ACCOR_BODY).stop().slideUp(1);

  $(SPMENU_ACCOR_ROOT).on('click', SPMENU_ACCOR_HEAD, function(e) {
    var $accordion = $(this).closest(SPMENU_ACCOR_ROOT);
    if (!$accordion.hasClass(SPMENU_ACCOR_OPEN_CLS)) {
      $accordion.addClass(SPMENU_ACCOR_OPEN_CLS)
        .children(SPMENU_ACCOR_BODY).stop().slideDown(SPMENU_ACCOR_SPEED);
    } else {
      $accordion.children(SPMENU_ACCOR_BODY).stop().slideUp(SPMENU_ACCOR_SPEED, function(){
        $accordion.removeClass(SPMENU_ACCOR_OPEN_CLS);
      });
    }
    return false;
  });
  
}

// リサイズ時の挙動:
function reflesh_spMenuAccordion(){
  if( $(SPMENU_ACCOR_ROOT).hasClass(SPMENU_ACCOR_OPEN_CLS) ){
    $(SPMENU_ACCOR_ROOT).removeClass(SPMENU_ACCOR_OPEN_CLS);
  }
  $(SPMENU_ACCOR_BODY).hide(); // --- 問答無用に閉じる
}

/*  -----------------------------------------------

_spFooterAccordion.js: SP表示時のフッターリンクのアコーディオン
  ※スコープを切ってその中で使用すること

-----------------------------------------------*/
var SPF_ACCOR_ROOT = '.js-spFooterAccordion';
var SPF_ACCOR_BODY = '.js-spFooterAccordion__body';
var SPF_ACCOR_HEAD = '.js-spFooterAccordion__head';
var SPF_ACCOR_OPEN_CLS = 'is-open';
var SPF_ACCOR_SPEED = 200; // 数値を変更しないこと！(mainNaviで参照)

//アコーディオン
function init_spFooterAccordion() {
  // 初期化処理
  $(SPF_ACCOR_ROOT).off('click', SPF_ACCOR_HEAD);
  $(SPF_ACCOR_BODY).stop().slideUp(1);

  $(SPF_ACCOR_ROOT).on('click', SPF_ACCOR_HEAD, function(e) {
    var $accordion = $(this).closest(SPF_ACCOR_ROOT);
    if (!$accordion.hasClass(SPF_ACCOR_OPEN_CLS)) {
      $accordion.addClass(SPF_ACCOR_OPEN_CLS)
        .children(SPF_ACCOR_BODY).stop().slideDown(SPF_ACCOR_SPEED);
    } else {
      $accordion.children(SPF_ACCOR_BODY).stop().slideUp(SPF_ACCOR_SPEED, function(){
        $accordion.removeClass(SPF_ACCOR_OPEN_CLS);
      });
    }
    return false;
  });
  
}

// リサイズ時の挙動:
function reflesh_spFooterAccordion(){
  if( $(SPF_ACCOR_ROOT).hasClass(SPF_ACCOR_OPEN_CLS) ){
    $(SPF_ACCOR_ROOT).removeClass(SPF_ACCOR_OPEN_CLS);
  }
  $(SPF_ACCOR_BODY).hide(); // --- 問答無用に閉じる
}

(function () {

  // video要素がないときは終了
  if( !document.querySelector('#js-indexpage-movie')) {
    return;
  }

  var bv = new Bideo();
  bv.init({
    // Video element
    videoEl: document.querySelector('#js-indexpage-movie'),

    // Container element
    container: document.querySelector('body'),

    // Resize
    resize: true,

    // autoplay: false,

    isMobile: window.matchMedia('(max-width: 768px)').matches,

    playButton: document.querySelector('#js-indexpage-play'),
    pauseButton: document.querySelector('#js-indexpage-pause'),

    // Array of objects containing the src and type
    // of different video formats to add
    src: [
      {
        src: '/images/index/DUMMY_mov_hts-samp005.mp4',
        type: 'video/mp4'
      }
    ],

    // What to do once video loads (initial frame)
    // onLoad: function () {
    //   document.querySelector('#js-indexpage-cover').style.display = 'none';
    // }
  });
}());
/* **********************************
 * areaPrint
 ********************************** */

// API: 指定領域のみを印刷（予め本文直下の子要素に.js-areaPrintを付与）
function areaPrint(printAreaID){

  // 印刷イベント
  var beforeFuncObj = function() {
    console.log('before printing.');
  };

  var afterFuncObj = function() {
    console.log('after printing.');
    // 領域印刷クラスを削除
    $('.js-areaPrint').removeClass("is-areaPrinting");
  };

  setupPrintEvent = function(beforeFuncObj, afterFuncObj){
    // IE 5.5 /Edge
    window.onbeforeprint = beforeFuncObj;
    window.onafterprint = afterFuncObj;

    // Firefox Chrome
    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener(function (mql) {
        if (mql.matches) {
          beforeFuncObj();
        } else {
          afterFuncObj();
        }
      });
    }
  };


  // 印刷イベントを初期化
  setupPrintEvent(beforeFuncObj, afterFuncObj);

  // 全ての領域印刷クラスを削除
  $('.js-areaPrint').removeClass("is-print");

  // 領域印刷クラスを付与
  $('.js-areaPrint').addClass("is-areaPrinting");

  // 指定領域「のみ」印刷時に表示させる
  var currentElm = $('#' + printAreaID);
  $(currentElm).addClass("is-print");

  // 印刷画面を開く
  window.print();

}

/* **********************************
 * scripts.js (Entry Point)
 ********************************** */

$(function(){

  // メガメニュー
  $('#js-gnav-01').gnavPanel({
    panelCloseSelecter: '.l-rightTop',
    targetSelecter: '#js-gnavPanel-01'
  });
  $('#js-gnav-02').gnavPanel({
    panelCloseSelecter: '.l-rightTop',
    targetSelecter: '#js-gnavPanel-02'
  });
  $('#js-gnav-03').gnavPanel({
    panelCloseSelecter: '.l-rightTop',
    targetSelecter: '#js-gnavPanel-03'
  });
  $('#js-gnav-04').gnavPanel({
    panelCloseSelecter: '.l-rightTop',
    targetSelecter: '#js-gnavPanel-04'
  });
  $('#js-gnav-05').gnavPanel({
    panelCloseSelecter: '.l-rightTop',
    targetSelecter: '#js-gnavPanel-05'
  });
  $('#js-gnav-06').gnavPanel({
    panelCloseSelecter: '.l-main',
    targetSelecter: '#js-gnavPanel-06'
  });

  // SPメニュー
  $( '#js-dlmenu' ).dlmenu({
    triggerSelector: ".js-spMenu-trigger",
    onMenuOpen : function() { 
      $("body").addClass("is-spmenu-open");
    },
    onMenuClose : function() {
      $("body").removeClass("is-spmenu-open");
    }
  });

  // SPメニュー内アコーディオン
  init_spMenuAccordion();

  // SP表示時のフッターリンクアコーディオン
  init_spFooterAccordion();

  // フォントUI
  $('body').pageSetting({
    key: "fontsize",
    valsList: ["medium", "small", "large"],
    triggerSelector: ".js-pagesetting-fontsize-btn",
    applyBtnSelector: "#js-pagesetting-applyBtn",
    resetBtnSelector: "#js-pagesetting-resetBtn",
    radioNameAttr: "fontsize-change"
  });

  // カラーUI
  $('body').pageSetting({
    key: "pagecolor",
    valsList: ["white", "black", "blue"],
    triggerSelector: ".js-pagesetting-pagecolor-btn",
    applyBtnSelector: "#js-pagesetting-applyBtn",
    resetBtnSelector: "#js-pagesetting-resetBtn",
    radioNameAttr: "pagecolor-change",
  });


  // リンク先ファイルのデータサイズ自動表示
  fileSize();

  // 高さを揃える (matchHeight)
  heightLine();

  // FAQアコーディオン
  accordion();

  // スムーススクロール
  pageScroll();

  // ページトップへ戻るボタンのアニメーション
  returnTop();

  // フッターのスライダー
  if ($('.js-fslider').length > 0) {
    commonSlider();
  }

  // L2トップのスライダー
  if ($('#js-topVisual-slider').length > 0) {
    $('#js-topVisual-slider').slick({
      autoplay: true,
      autoplaySpeed: 3000, // 自動再生のスライド切り替えまでの時間
      speed: 300, // スライド/フェードアニメーションの速度
      infinite: true,
      swipe: false,
      touchMove: false,
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      accessibility: false, // キー入力無効化
      dots: false,
      arrows: false,
      responsive: [{  breakpoint: 767, settings: { } }]
    });
  }

  // tabel要素のレスポンシブ対応
  $('table').responsiveTable();

  // モーダル
  $('.js-modal').modaal();

});


/* **********************************
 * scripts.js (modules)
 ********************************** */
// File Size get
function fileSize() {
  $('.l-main__middle a[href]').each(function () {
    var $this = $(this);
    var href = $this.attr('href');

    if (!$this.hasClass('js-fileSizeNone')) {
      if (href.match(/\.(PDF|DOC|DOCX|XLS|XLSX|PPT|PPTX|ZIP)$/i)) {
        var file_size = $.ajax({
          type: "HEAD",
          url: href,
          cache: false
        }).done(function () {
          /* success */
          var hrefLength = href.length;
          var dotted = href.lastIndexOf(".");
          var sExtension = href.substring(dotted + 1, hrefLength);
          sExtension = sExtension.toUpperCase();
          var filesize = formatFileSize(file_size.getResponseHeader("Content-Length"));

          var sizeText = ' (' + sExtension + '：' + filesize + ') ';

          if ($this.hasClass('c-reportList__link')) {
            $this.find('.c-report__pdf__text').append('<span class="u-fwNormal">（' + filesize + '）</span>');
          } else if ($this.hasClass('c-newsList__link')) {
            $this.find('.c-newsList__text').append(sizeText);
          } else if ($this.hasClass('c-btnLink__link')) {
            $this.append('<span class="c-btnLink__link__sizeText">' + sizeText + '</span>');
          } else {
            $this.append('<span class="c-icon--sizeText">' + sizeText + '</span>');
          }

        }).fail(function () {
          /* error */
          console.log('error');
        });
      }
    }
  });

  function formatFileSize(size) {
    var unit = [' Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    for (var i = 1; i < unit.length; i++) {
      if (size < Math.pow(1024, i)) return (Math.round((size / Math.pow(1024, i - 1)) * 1) / 1) + unit[i - 1];
    }
    return size;
  }
}

// 高さを揃える
function heightLine() {
  $('.boxHeight').matchHeight();
}


// アコーディオン
function accordion() {
  // 初期化処理
  $('.js-accordion').off('click', '.js-accordion__head');

  $('.js-accordion').on('click', '.js-accordion__head', function(e) {
    var $accordion = $(this).closest('.js-accordion');
    if (!$accordion.hasClass('open')) {
      $accordion.addClass('open').children('.js-accordion__body').stop().slideDown();
    } else {
      $accordion.children('.js-accordion__body').stop().slideUp(function(){
        $accordion.removeClass('open')
      });
    }
    return false;
  });

  //アコーディオンの展開
  var getURL = location.href;
  if ( getURL.indexOf('?q=') === -1) {
    var URLID = getURL.split('#');
    if(URLID[1]) {
      var $getID = '#' + URLID[1];
      if($($getID).is('.accordion')) {
        $($getID).addClass('open');
        $($getID).children('.js-accordion__body').show();
      }
    }
  }
}


// slider
function commonSlider() {
  var $fSlider = $('.js-fslider');
  $fSlider.slick({
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    arrows: false,
      responsive: [
      {
        breakpoint: 767,
          settings: {
            arrows: false,
            dots: true,
            centerMode: false,
//            centerPadding: '60px',
            slidesToShow: 2
          }
      }]
  });

  // (不要なのでコメントアウト) dotsにストップボタンの追加
  function stopBtn() {
    // $('.js-fslider .slick-dots').append('<li class="stopBtn"><a href="javascript: void(0);">stop</a></li>');
    // slideStop();  // ストップボタンをクリックした時の挙動
  }

  // レスポンシブ時にストップボタンの再生成
  $fSlider.on('breakpoint', function(event, slick, breakpoint){
    stopBtn();
  });

  // ストップボタンの生成
  stopBtn();
}

function slideStop() {
  var playFlg = true;

  $('.stopBtn a').on('click', function() {
    if(playFlg) {
      $(this).addClass('is-active');
      $(this).closest('.slick-slider').slick('slickPause');  //スライダーストップ
      playFlg = false;
    } else {
      $(this).removeClass('is-active');
      $(this).closest('.slick-slider').slick('slickPlay');  //スライダー再開
      playFlg = true;
    }
  });
}

// スムーススクロール
function pageScroll() {
  var not = ':not(.js-noPageScroll):not(.js-eventModal)';
  $('a[href^="#"]' + not).on('click', function () {
    var hrefStr = $(this).attr("href");
    if( !hrefStr || hrefStr === "#"){
      return;
    }
    var offsetObj = $(hrefStr).offset();
    if(offsetObj && offsetObj.top){
      $("html,body").animate({ scrollTop: offsetObj.top }, "slow", "swing");
    }else{
      $("html,body").animate({ scrollTop: 0 }, "slow", "swing");
    }
    return false;
  });
}

// ページトップへ戻るボタンのアニメーション
function returnTop() {
  var $pageTop = $('.js-return');
  $pageTop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $pageTop.fadeIn();
    } else {
      $pageTop.fadeOut();
    }
  });
}

// tabel要素のレスポンシブ対応
(function($){
  $.fn.responsiveTable = function(config){
    var defaults = {
      // captionText: 'スクロールしてご確認ください。',
      size: 1
    };

    var options = $.extend(defaults, config);
    return this.each(function(){
      var $this = $(this);
      var $cells = $this.find('tr:first > *');
      var table_size = $this.find('tr:first > *').length;

      for (var i = 0; i < $cells.length; i++) {
        var $cell = $cells.eq(i);
        var colspan = $cell.attr('colspan');

        if ( colspan ) {
          table_size = table_size + ( colspan - 1 );
        }
      }

      if ( table_size > options.size || $this.is('.sp-scrollTable') ) {
        $this.wrap('<div class="c-tableWrapper"></div>');

        var $wrapper = $this.closest('.c-tableWrapper');
        $wrapper.before('<p class="c-tableCaption"><span>スクロールしてご確認ください。</span></p>');

      }
    });
  };
})(jQuery);

